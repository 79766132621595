import React from "react";
import { presentationTrips } from "../components/Trips/trips-data";
import MoreInformation from "../components/Trips/MoreInformation";
import { Trans } from "react-i18next";

const tripInformation = presentationTrips.find((x) => x.id === "pollensa-trip");

const Pollensa = () => {
  return (
    <MoreInformation
      title="Cruise around Pollensa Bay"
      description="Our Pollensa glass bottom boat excursion will take you on a beautiful cruise aboard a submarine vision boat to explore the beautiful bay of Pollensa, considered one of the best places in Mallorca for a boat trip for its scenery and views."
      tripInformation={tripInformation}
      timeTable={
        <>
          <Trans i18nKey={tripInformation.moreInformation.timetable}>
            <ul>
              <li>Item 1</li>
              <li>Item 2</li>
            </ul>
          </Trans>
        </>
      }
    />
  );
};

export default Pollensa;
